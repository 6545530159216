import React from "react"

import cn from "@utils/classNames"

import Title from "@app/content/Title"
import Projects from "@app/content/Projects"
import LinkList from "@app/content/LinkList"
import Footer from "@app/content/Footer"

import "./Content.css"

const Content = ({ className = "" }: { className?: string }) => (
  <main className={cn(className, "content")}>
    <Title
      className="content__title"
      title="Nico Martin"
      subtitle="Recap 2020"
      intro="2020 has been a crazy year. We had a lot of ups and downs but I am super thankfull that for me over the whole year, the ups clearly outweighed the downs. In retrospect, I realized that an enormous amount happened this year so I would like to capture that in this review."
      url="https://2020.nico.dev"
      stats={[
        "7 Talks",
        "14 Articles",
        "2 Podcasts",
        "5 finished side-projects",
      ]}
    />
    <div className="content__content post-content">
      <h2>Family</h2>
      <p>
        In Switzerland, we were quite fortunate that we never had a hard
        lockdown. We had a couple of weeks where the schools had to close and we
        were told to stay home as much as possible. But since our kids are not
        yet in school and we have a big garden it did not affect us too much.
        <br />
        Of course, we had to make some arrangements at various points. But all
        in all it was not a bad year.
      </p>
      <h2>Work</h2>
      <p>
        In September 2019 I started freelancing for{" "}
        <a href="https://www.valtech.com/" target="_blank">
          Valtech
        </a>{" "}
        where I was involved with a team from{" "}
        <a href="https://www.dxc.technology/ch">DXC</a> and worked on the
        e-banking client portal for a swiss bank. A quite large React/Redux
        application.
      </p>
      <p>
        It was originally planned for six months and after that we were able to
        extend the cooperation for another 11 months. Given the fact that with
        corona we were facing a quite uncertain economic situation it was a
        relieve to know that even if our other clients at{" "}
        <a href="https://sayhello.ch" target="_blank">
          Say Hello
        </a>{" "}
        had to cut budgets we would make it through the "crisis". I wrote that
        in quotes because luckily for us in the end, we had hardly any losses.
        But we did not know that at the time.
      </p>
      <p>
        So four days a week I was working for valtech, the fifth I was still
        helping out at{" "}
        <a href="https://sayhello.ch" target="_blank">
          Say Hello
        </a>
        .
      </p>
      <p>
        Also when DXC changed to home office, it didn't have a negative effect
        on me since I am used to work from various places.
      </p>
      <h2>Speaking</h2>
      <p>
        As you all know, most of the conferences in 2020 were cancelled or moved
        to an online event. But I was still able to have some talks at online
        meetups and conferences this year. Some highlights this year:
      </p>
      <LinkList
        links={[
          {
            title: "WeAreDevelopers - LiveWeek",
            description: "Progressive Web Apps - The next big thing",
            urls: [
              "https://slides.nico.dev/pwa-wearedevs/",
              "https://www.youtube.com/watch?t=3844&v=NSvE38Z72MU",
            ],
          },
          {
            title: "Bärner JS Talks",
            description: "Push Notifications on the web",
            urls: ["https://slides.nico.dev/push-notifications-berner-js/"],
          },
          {
            title: "London PWA + Oslo PWA",
            description: "Push Notifications on the web",
            urls: ["https://slides.nico.dev/push-notifications-london-oslo/"],
          },
          {
            title: "DigiSnack",
            description: "Push Notifications on the web",
            urls: ["https://slides.nico.dev/digisnack-push-notifications/"],
          },
          {
            title: "Vue Berne",
            description: "Progressive Web Apps - The next big thing",
            urls: ["https://slides.nico.dev/vueberne-pwa/"],
          },
        ]}
      />

      <h2>Writing</h2>
      <p>
        In spring I finished some side-projects and I was looking for a way to
        record some of my learnings. And since most conferences and meetups were
        cancelled, I started with a{" "}
        <a href="https://dev.to/nicomartin" target="_blank">
          dev.to account
        </a>
        :
      </p>
      <LinkList
        links={[
          {
            title: "the correct way to fetch data with react hooks",
            urls: [
              "https://dev.to/nicomartin/the-right-way-to-fetch-data-with-react-hooks-48gc",
            ],
          },
          {
            title: "How to test async React hooks",
            urls: [
              "https://dev.to/nicomartin/how-to-test-async-react-hooks-392j",
            ],
          },
          {
            title: "How to create a progressive audio player with React hooks",
            urls: [
              "https://dev.to/nicomartin/how-to-create-a-progressive-audio-player-with-react-hooks-31l1",
            ],
          },
          {
            title: "Media Session API",
            urls: ["https://dev.to/nicomartin/media-session-api-c1j"],
          },
          {
            title: "Neumorphism Audio Player",
            urls: ["https://dev.to/nicomartin/neumorphism-audio-player-4coa"],
          },
          {
            title: "Share Target API",
            urls: ["https://dev.to/nicomartin/share-target-api-5263"],
          },
        ]}
      />
      <p>
        I also contacted CSS-tricks and got the chance to write an{" "}
        <a
          href="https://css-tricks.com/creating-scheduled-push-notifications/"
          target="_blank"
        >
          article about the notification trigger API
        </a>
        . It was crazy to contribute to this platform, that helped me so much
        through my self-taught career in web development!
      </p>
      <p>
        Coming to the end of the year I created a project called{" "}
        <a href="https://pwadvent.dev" target="_blank">
          PWAdvent
        </a>
        , an advent calendar for the PWA community. A lot of the articles were
        written by various developers from the PWA community. But I did my part
        as well:
      </p>
      <LinkList
        links={[
          {
            title: "Add to Homescreen",
            urls: ["https://pwadvent.dev/day/1/"],
          },
          {
            title: "Sharing on the web",
            urls: ["https://pwadvent.dev/day/2/"],
          },
          {
            title: "Push Notifications",
            urls: ["https://pwadvent.dev/day/9/"],
          },
          {
            title: "Media Session API",
            urls: ["https://pwadvent.dev/day/10/"],
          },
          {
            title: "Contact Picker API",
            urls: ["https://pwadvent.dev/day/16/"],
          },
          {
            title: "File System Access API",
            urls: ["https://pwadvent.dev/day/20/"],
          },
          {
            title: "File Handling API",
            urls: ["https://pwadvent.dev/day/21/"],
          },
        ]}
      />
      <h2>Podcasting</h2>
      <p>
        Also in 2020, I had my first appearances in podcasts. And I was quite
        stoked that my very first podcast has been with the{" "}
        <a
          href="https://www.programmier.bar/podcast-episodes/folge-75-wie-gehts-eigentlich-wordpress-mit-nico-martin"
          target="_blank"
        >
          programmier.bar
        </a>
        . A podcast I am listening to myself whenever they publish something
        new.
      </p>
      <p>
        After that, Peter Suhm started his "
        <a href="https://www.billablehours.co/" target="_blank">
          billable hours
        </a>
        " series, a podcast for WordPress agencies. There I had a chat with him
        about{" "}
        <a
          href="https://www.billablehours.co/episodes/progressive-web-apps-with-nico-martin"
          target="_blank"
        >
          Progressive Web Apps and WordPress
        </a>
        .
      </p>
      <h2>Side-projects</h2>
      <p>
        In 2020 I had a lot of ideas. But only a few of them have seen the light
        of day:
      </p>
      <Projects />
      <p>
        Besides that, I was able to invest some time into{" "}
        <a href="https://wordpress.org/plugins/progressive-wp/" target="_blank">
          Progressive WordPress
        </a>
        . The next release will be huge😍.
      </p>
      <h2>An outlook to 2021</h2>
      <p>
        After all, 2020 was quite successful but also quite exhausting. Working
        full time, having all my side-projects, my family and then trying to
        also find time for some skiing, skating and windsurfing wasn't easy.
      </p>
      <p>
        That's why 2021 will be a lot different. Last spring we bought a caravan
        and from April 2021 on we will travel with it through Europe. We are
        super excited for this adventure and since we will mostly live from
        savings{" "}
        <b>
          I will also be able to invest more in projects, talks and the
          community around PWA.
        </b>
      </p>
    </div>
    <Footer className="content__footer" />
  </main>
)

export default Content
