import React from "react"

import cn from "@utils/classNames"
import { formatLink } from "@utils/format"
import {
  IconTwitter,
  IconLinkedIn,
  IconDev,
  IconWordPress,
  IconGitHub,
  IconDribble,
} from "@app/theme"

import "./Footer.css"

const Footer = ({ className = "" }: { className?: string }) => (
  <footer className={cn(className, "footer")}>
    <p className="footer__nico-dev">
      <a
        href="https://nico.dev/"
        target="_blank"
        className="footer__nico-dev-link"
      >
        {formatLink("https://nico.dev/")}
      </a>
    </p>
    <nav className="footer__sm">
      {[
        [IconTwitter, "https://twitter.com/nic_o_martin"],
        [IconLinkedIn, "https://www.linkedin.com/in/martinnico/"],
        [IconDev, "https://dev.to/nicomartin"],
        [IconWordPress, "https://profiles.wordpress.org/nico_martin/"],
        [IconGitHub, "https://github.com/nico-martin"],
        [IconDribble, "https://dribbble.com/nicomartin"],
      ].map(([Component, link]: [any, string]) => (
        <a href={link} className="footer__sm-link">
          <Component className="footer__sm-icon" />
        </a>
      ))}
    </nav>
  </footer>
)

export default Footer
