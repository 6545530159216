import React from "react"
import { PageProps } from "gatsby"
import Helmet from "react-helmet"

import cn from "@utils/classNames"

import Content from "@app/Content"
import Image from "@app/Image"

import "./App.css"

const TITLE = "Nico Martin ❄ recap 2020"
const DESC = "2020 has been a crazy year. Time for a little recap."

const App = ({ pageProps }: { pageProps: PageProps }) => {
  React.useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    window.addEventListener("resize", () => {
      vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    })
  }, [])

  return (
    <div
      className={cn("app", {
        "app--image": pageProps.location.pathname === "/image/",
      })}
    >
      <Helmet defer={false}>
        <title>{TITLE}</title>
        <meta name="description" content={DESC} />
        <meta
          property="og:image"
          content="https://2020.nico.dev/facebook-image-2.png"
        />
        <meta property="og:title" content={TITLE} />
        <meta property="og:description" content={DESC} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={TITLE} />
        <meta name="twitter:description" content={DESC} />
        <meta
          name="twitter:image"
          content="https://2020.nico.dev/twitter-image-2.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Image className="app__image" />
      <div className="app__shadow">
        <div className="app__shadow-polygon" />
      </div>
      <div className="app__shadow-overlay" />
      <Content className="app__content" />
    </div>
  )
}

export default App
