import React from "react"

import cn from "@utils/classNames"
import { formatLink } from "@utils/format"

import "./LinkList.css"

export interface LinkListLinkProps {
  title: string
  description?: any
  urls: string[]
}

const LinkList = ({
  className = "",
  links = [],
}: {
  className?: string
  links: LinkListLinkProps[]
}) =>
  links.length === 0 ? null : (
    <ul className={cn(className, "link-list")}>
      {links.map(({ title, description = null, urls }) => (
        <li className="link-list__item">
          <p className="link-list__title">{title}</p>
          {description && (
            <div className="link-list__description">{description}</div>
          )}
          <p className="link-list__link-p">
            {urls.map(url => (
              <a href={url} target="_blank" className="link-list__link">
                {formatLink(url)}
              </a>
            ))}
          </p>
        </li>
      ))}
    </ul>
  )

export default LinkList
