import React from "react"

const WordPress = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.98 96.98" {...props}>
    <path d="M49.16 51.833L37.694 85.152a38.323 38.323 0 0 0 10.798 1.558c4.449 0 8.719-.77 12.689-2.167a3.393 3.393 0 0 1-.271-.527L49.16 51.833zM10.272 48.488c0 15.129 8.792 28.202 21.541 34.396l-18.23-49.949a38.082 38.082 0 0 0-3.311 15.553z" />
    <path d="M74.289 46.56c0-4.723-1.695-7.993-3.149-10.541-1.938-3.148-3.754-5.813-3.754-8.962 0-3.513 2.664-6.783 6.418-6.783.17 0 .33.021.496.029-6.798-6.227-15.856-10.031-25.807-10.031-13.354 0-25.101 6.85-31.932 17.227a84.82 84.82 0 0 0 2.459.046c3.998 0 10.187-.485 10.187-.485 2.062-.124 2.302 2.903.245 3.146 0 0-2.071.243-4.374.365l13.915 41.397 8.363-25.085L41.4 30.57a70.58 70.58 0 0 1-4.007-.365c-2.058-.12-1.818-3.268.241-3.146 0 0 6.313.485 10.066.485 3.997 0 10.188-.485 10.188-.485 2.062-.122 2.303 2.903.243 3.146 0 0-2.073.243-4.374.365L67.57 71.653l3.812-12.738c1.651-5.286 2.907-9.084 2.907-12.355zM82.025 30.153c.164 1.216.258 2.525.258 3.93 0 3.878-.723 8.238-2.905 13.689L67.703 81.523c11.361-6.626 19.006-18.936 19.006-33.033a37.995 37.995 0 0 0-4.684-18.337z" />
    <path d="M48.49 0C21.71 0 .001 21.71.001 48.49S21.71 96.98 48.49 96.98s48.489-21.71 48.489-48.49S75.27 0 48.49 0zm.002 90.997c-23.44 0-42.507-19.067-42.507-42.509 0-23.438 19.066-42.505 42.507-42.505 23.437 0 42.503 19.068 42.503 42.505.001 23.44-19.067 42.509-42.503 42.509z" />
  </svg>
)

export default WordPress
