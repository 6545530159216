import React from "react"

import { formatLink } from "@utils/format"

import "./Projects.css"

interface ProjectType {
  title: string
  image: string
  content: any
  links?: Record<string, string>
}

const PROJECTS: ProjectType[] = [
  {
    title: "YTAudio",
    content: (
      <React.Fragment>
        <p>
          In the beginning of 2020, I've stumbled upon a{" "}
          <a
            href="https://twitter.com/JNYBGR/status/1216302609222467584"
            target="_blank"
          >
            Twitter-thread
          </a>{" "}
          where{" "}
          <a href="https://twitter.com/aleksejdix" target="_blank">
            Aleksej
          </a>{" "}
          and{" "}
          <a href="https://twitter.com/JNYBGR" target="_blank">
            Jonny
          </a>{" "}
          were talking about a web app that would allow you to listen to the
          audio stream of a YouTube video in the background.
        </p>
        <p>Long story short, I built it😊.</p>
        <p>
          Unfortunately due problems with the library I used to get the audio
          file from Youtube it does not work anymore. But it was still great to
          learn a bunch of stuff.
        </p>
      </React.Fragment>
    ),
    image: "ytaudio",
    links: {
      url: "https://ytaud.io/",
      github: "https://github.com/nico-martin/yt-audio",
      dev:
        "https://dev.to/nicomartin/how-to-create-a-progressive-audio-player-with-react-hooks-31l1",
    },
  },
  {
    title: "Eurorelief",
    content: (
      <React.Fragment>
        <p>
          In April 2020, Tim Schoch and his family travelled to Lesbos (GR) to
          volunteer at the refugee camp in Moria.
        </p>
        <p>
          Through his eyes as a UX designer, he quickly saw a lot of places,
          where a digital solution could improve the situation of the almost
          20'000 refugees waiting for their asylum request to be processed (note
          that the camp was built for max 3000 refugees)
        </p>
        <p>
          Together with Eurorelief (the organization behind the camp), me and a
          couple of friends created a notification system that could have
          improved the daily lives of the refugees a lot. After a huge fire
          destroyed the camp, Eurorelief had to focus on other problems so our
          app has not yet launched.
        </p>
      </React.Fragment>
    ),
    image: "eurorelief",
    links: {
      github: "https://github.com/hackantons/eurorelief-app-frontend",
    },
  },
  {
    title: "Investment Simulator",
    content: (
      <React.Fragment>
        <p>
          At this years{" "}
          <a href="https://hackathon.bscyb.ch/" target="_blank">
            YBHackathon
          </a>{" "}
          we have been assigned the challenge of developing a simple approach to
          investing and saving.
        </p>
        <p>
          The subject itself was not as exciting as we hoped. But we found a
          quite nice solution: we created a game where users invest money and
          manage their portfolio based on events.
        </p>
      </React.Fragment>
    ),
    image: "bekb",
    links: {
      url: "https://simulator.bekb.app/",
      github: "https://github.com/hackantons/yb-2020-frontend",
    },
  },
  {
    title: "PWAdvent",
    content: (
      <React.Fragment>
        <p>
          In October I was thinking about an advent calendar for the tech-/ web
          dev community. And since PWA is by far the most exciting subject I
          based it around those features.
        </p>
        <p>
          Also instead of me writing summaries of features I barely know I
          reached out to other dev in the PWA community. It was all a huge
          success. 12 authors wrote about their favourite subjects and until now
          we have more the 65'000 article views! I never would have dreamed
          about such numbers when I was working on the idea.
        </p>
      </React.Fragment>
    ),
    image: "pwadvent",
    links: {
      url: "https://pwadvent.dev/",
      github: "https://github.com/nico-martin/PWAdvent/",
    },
  },
  {
    title: "[md.edit]",
    content: (
      <React.Fragment>
        <p>
          As part of PWAdvent I wanted to know more about the File System Access
          API and the File Handling API. So I created a Progressive Web App
          where the user can create, open, edit and save markdown files.
        </p>
        <p>
          It's not only a pretty useful tool I use myself quite often, it also
          opened up a whole new world of web development. Right now I have quite
          a lot of new projects in mind where I want to use web technologies to
          create desktop applications that can improve productivity. But that’s
          content for next years review😉
        </p>
      </React.Fragment>
    ),
    image: "mdedit",
    links: {
      url: "https://md.nico.dev",
      github: "https://github.com/nico-martin/markdown-editor/",
    },
  },
]

const Projects = () => (
  <React.Fragment>
    {PROJECTS.map(({ title, image, content, links = {} }) => (
      <div className="project">
        <h3 className="project__title">{title}</h3>
        <div className="project__main">
          {image && (
            <picture className="project__img">
              <source srcSet={`projects/${image}.webp`} type="image/webp" />
              <source srcSet={`projects/${image}.png`} type="image/png" />
              <img src={`projects/${image}.png`} alt={title} />
            </picture>
          )}
          <div className="project__content">
            {content}
            {Object.entries(links).length !== 0 && (
              <ul className="project__links">
                {Object.entries(links).map(([key, url]) => (
                  <li className="project__link-item" key={key}>
                    <a href={url} className="project__link" target="_blank">
                      {formatLink(url)}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    ))}
  </React.Fragment>
)

export default Projects
