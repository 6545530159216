import React from "react"

import cn from "../utils/classNames"

import "./Image.css"

const Image = ({ className = "" }: { className?: string }) => {
  const ref = React.useRef(null)
  const [hasImage, setHasImage] = React.useState(false)

  const supportsWebp = () =>
    new Promise(resolve => {
      const img = document.createElement("img")
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
      img.src =
        "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA"
    })

  React.useEffect(() => {
    if (ref.current) {
      supportsWebp().then(support => {
        if (hasImage) {
          return
        }
        setHasImage(true)

        const type = support ? "webp" : "jpg"
        const images = []
        const sizes = [500, 700, 1000, 1500, 2000]
        sizes.forEach(function (size) {
          images.push(
            "/portrait/nico-martin-" + size + "." + type + " " + size + "w"
          )
        })

        const img = document.createElement("img")
        img.srcset = images.join(", ")
        img.src = "/portrait/nico-martin-2000." + type
        img.alt = "Nico Martin - Frontend Developer"
        img.className = "image__img"
        ref.current.appendChild(img)
      })
    }
  }, [ref])

  return (
    <div className={cn(className, "image")} ref={ref}>
      <noscript>
        <img
          src="/portrait/nico-martin-2000.jpg"
          alt="Nico Martin - Frontend Developer"
          className="image__img"
        />
      </noscript>
    </div>
  )
}

export default Image
