import React from "react"

import cn from "@utils/classNames"
import { GradientText } from "@app/theme"

import "./Title.css"

const Title = ({
  className = "",
  title,
  subtitle = "",
  intro = "",
  stats = [],
  url = "",
}: {
  className?: string
  title: string
  subtitle?: string
  intro?: string
  stats?: string[]
  url?: string
}) => (
  <header className={cn(className, "title")}>
    <h1 className="title__heading">
      <GradientText tagType="span" className="title__title">
        {title}
      </GradientText>
      <span className="title__subtitle heading heading--h2">{subtitle}</span>
    </h1>
    {stats.length !== 0 && (
      <ul className="title__stats">
        {stats.map(stat => (
          <li>{stat}</li>
        ))}
      </ul>
    )}
    <p className="title__intro">{intro}</p>
    {url !== "" && <p className="title__url">{url}</p>}
  </header>
)

export default Title
