import React, { ReactHTML } from "react"

import cn from "@utils/classNames"

import "./GradientText.css"

const GradientText = ({
  className = "",
  children,
  tagType = "h1",
  ...props
}: {
  className?: string
  children: string
  tagType?: keyof ReactHTML
  [key: string]: any
}) =>
  React.createElement(
    tagType,
    {
      ...props,
      className: cn(className, "gradient-text"),
    },
    React.createElement(
      "span",
      {
        className: "gradient-text__span",
      },
      children
    )
  )

export default GradientText
